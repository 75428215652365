<template>
	<div class="ho-autocomplete">
		<Multiselect
			v-model="value"
			:searchable="true"
			:placeholder="placeholder"
			:options="options"
			:canClear="canClear"
			:canDeselect="false"
			@select="handleSelect"
			@clear="handleClear"
		/>
	</div>
</template>

<script setup lang="ts">
import { ref, watch, Ref } from "@vue/runtime-core";
import Multiselect from '@vueform/multiselect';
import { i18n } from '@/i18n';

const props = defineProps({
	placeholder: {
		type: String,
		default: () => i18n.global.t('autocomplete.select-options')
	},
	options: {
		type: Array,
		default: () => []
	},
	defaultValue: {
		type: [String, Number],
		default: () => null
	},
	canClear: {
		type: Boolean,
		default: () => true
	}
});
const emit = defineEmits(["onChange"]);
const value: Ref<string | number> = ref(props.defaultValue);

watch(() => props.defaultValue, () => value.value = props.defaultValue);

const handleSelect = (val: any) => {
	emit("onChange", val)
}

const handleClear = () => {
	emit("onChange", null)
}
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<style lang="postcss">
	.ho-autocomplete {
		--ms-font-size: 1.4rem;
		--ms-option-font-size: 1.4rem;
		--ms-option-py: 0.75rem;
		--ms-dropdown-radius: 4px;
		--ms-border-width: 1px;
		--ms-placeholder-color: #c2c2c2;
		--ms-max-height: 600px;
		width: auto;
	}

	.ho-autocomplete .multiselect,
	.ho-autocomplete .multiselect input[type="text"] {
		background-color: transparent;
		--ms-bg: transparent;
		cursor: pointer;
		font-weight: 800;
	}

	.ho-autocomplete .multiselect .multiselect-single-label { 
		width: calc(100% - 35px);
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		display: block;
		position: relative;
		margin-right: auto;
		padding-left: 8px; padding-right: 8px;
	}

	.ho-autocomplete .multiselect.is-active .multiselect-single-label { opacity: 0.2; }

	.ho-autocomplete .multiselect .multiselect-caret,
	.ho-autocomplete .multiselect .multiselect-clear-icon { 
		width: 1rem;
		height: 1.5rem;
	}

	.ho-autocomplete .multiselect .multiselect-dropdown {
		overflow-y: auto;
		font-weight: 400;
		max-height: 300px;
	}

	.multiselect-option.is-selected,
	.multiselect-option.is-selected.is-pointed {
		@apply bg-peri-400
	}
</style>