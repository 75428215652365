<template>
	<form @submit.prevent="" :autocomplete="disableAutocomplete ? 'off' : 'on'">
		<slot name="body"></slot>

		<div class="ho-form-footer">
			<slot name="footer">
				<button type="button" class="btn" @click="handleOk" :disabled="disableOk" v-if="!hideOk">
					{{okButtonText}}
				</button>
				<button type="button" class="btn btn-outline" @click="handleCancel" :disabled="disableCancel" v-if="!hideCancel">
					{{cancelButtonText}}
				</button>
			</slot>
		</div>
	</form>
</template>

<script setup lang="ts">
import { i18n } from '@/i18n';

defineProps({
	hideCancel: {
		type: Boolean,
		default: false
	},
	hideOk: {
		type: Boolean,
		default: false
	},
	okButtonText: {
		type: String,
		default: i18n.global.t('common.ok')
	},
	cancelButtonText: {
		type: String,
		default: i18n.global.t('common.cancel')
	},
	disableOk: {
		type: Boolean,
		default: false
	},
	disableCancel: {
		type: Boolean,
		default: false
	},
	disableAutocomplete: {
		type: Boolean,
		default: false
	}
});
const emit = defineEmits(['onOk', 'onCancel']);
//handle Dirty state

const handleOk = ()=>{
	emit('onOk');
}
const handleCancel = ()=>{
	emit('onCancel');
}
</script>

<style lang="postcss" scoped>
.ho-form-footer {
	display: flex;
	justify-content: flex-end;
	padding: 16px 0;
}

</style>