<template>
	<thead>
		<tr>
			<th class="ho-head-checkbox ho-cell-static w-18 px-8 ui-test-checkbox-cell" v-if="selectable">
				<input v-if="hasSelectAll" type="checkbox" name="select-all" v-model="isSelectAllMode" @change="handleSelectAllCheck">
			</th>
			<grid-head-cell
				v-for="(item, i) in columnsConfig" 
				:key="i" 
				:item="item" 
				:loading="loading"
				:sortOptions="sortOptions"
				:style="getCellWidthStyle(item.width, item.minWidth)"
				:class="item.class"
				@onSortingChange="handleSortingChange"
			/>
			<th class="ho-head-actions ho-cell-static w-14 border-l" v-if="hasFilterOptions" >
				<button 
					class="ho-icon-btn filter-btn ui-test-grid-head-button" 
					:class="{ active: filterActive }"
					@click="onFilterClick"
				>
					<FilterIcon class="ho-btn-state-normal" />
					<FilterIconSolid class="ho-btn-state-hover" />
				</button>
			</th>
		</tr>
		<tr class="ho-filters-row ui-test-filters-row" v-if="filterActive">
			<th class="ho-cell-static w-14"></th>
			<grid-head-cell-filter
				v-for="(item, i) in columnsConfig" 
				:key="i" 
				:item="item" 
				:style="getCellWidthStyle(item.width, item.minWidth)"
				:filterActive="filterActive"
				@onFiltersChange="handleFilterChange"
			/>
			<th class="ho-cell-static w-14 border-none"></th>
		</tr>
	</thead>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import GridHeadCell from "./grid-head-cell.vue";
import GridHeadCellFilter from "./grid-head-cell-filter.vue";
import IGridHeadCellConfig from "./interfaces/iGridHeadCellConfig";
import { FilterIcon } from '@heroicons/vue/outline';
import { FilterIcon as FilterIconSolid } from '@heroicons/vue/solid';
import { getCellWidthStyle } from "./shared/gridUtils";
import ISortOptions from '../../interfaces/invoices/iSortOptions';

const props = defineProps({
	loading: Boolean,
	columnsConfig: {
		type: Array as () => IGridHeadCellConfig[]
	},
	selectAllMode: Boolean,
	hasSelectAll: {
		type: Boolean,
		default: () => false
	},
	filterableHeader: {
		type: Boolean,
		default: () => false
	},
	selectable: {
		type: Boolean,
		default: () => false
	},
	sortOptions: {
		type: Object as () => ISortOptions
	}
});
const emit = defineEmits([
	'onFiltersActive', 
	'onFiltersChange', 
	'onSortingChange',
	'onSelectAllChange'
]);
	
const filterActive = ref(false);
const isSelectAllMode = ref(props.selectAllMode);

const onFilterClick = () => {
	const isFiltersActive = !filterActive.value;
	filterActive.value = isFiltersActive;
	emit('onFiltersActive', isFiltersActive);
}

const handleFilterChange = (selection: any[]) => {
	emit('onFiltersChange', selection);
}

const handleSortingChange = (field: string, value: "asc" | "desc") => {
	emit('onSortingChange', field, value);
}

const handleSelectAllCheck = (ev: any) => {
	emit('onSelectAllChange', ev.target.checked)
}

const hasFilterOptions = computed(() => {
	return props.filterableHeader 
		? (props.columnsConfig || []).filter(c => c.filterOptions).length > 0
		: false;
})

watch(() => props.selectAllMode, (newValue) => {
	isSelectAllMode.value = newValue;
});
</script>

<style lang="postcss" scoped>
	table thead tr th.ho-head-actions:hover { 
		border-right: none;
		@apply bg-transparent 
	}

  table thead tr.ho-filters-row th { @apply pt-2 pb-3}
	
	table thead tr.ho-filters-row th:hover { 
		border-left: none; border-right: none;
		@apply bg-transparent 
	}	
</style>