<template>
	<div class="ho-date-picker">
		<label><CalendarIcon class="h-6 w-6"/></label>
		<date-picker 
			class="ho-date-input"
			v-model="currStartDate" 
			:upperLimit="currEndDate"
		/>
		<label><ChevronRightIcon class="w-8" /></label>
		<date-picker 
			class="ho-date-input border-l-2"
			v-model="currEndDate" 
			:lowerLimit="currStartDate"
			:upperLimit="new Date(Date.now())"
		/>
	</div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import DatePicker from 'vue3-datepicker';
import { CalendarIcon, ChevronRightIcon } from '@heroicons/vue/outline';
import IDateRangeValues from './interfaces/iDateRangeValues';

const props = defineProps({
	startDate: Date,
	endDate: Date
});
const emit = defineEmits(["onChange"]);
const currStartDate = ref(props.startDate);
const currEndDate = ref(props.endDate);

watch(() => [currStartDate.value, currEndDate.value], () => {
	valuesChanged()
});

const valuesChanged = () => {
	const values: IDateRangeValues = {
		startDate: currStartDate.value,
		endDate: currEndDate.value
	};
	emit("onChange", values)
}
</script>

<style lang="postcss">

.v3dp__datepicker {
	--vdp-hover-bg-color: transparent;
	--elem-selected-bg-color: transparent;
	--vdp-elem-font-size: 1.4rem;
	--vdp-elem-border-radius: 2px;
	--vdp-border-radius: 2px;
}

.v3dp__elements button:hover,
.v3dp__elements button.selected {
	@apply bg-peri-400 rounded
}

.ho-date-picker { @apply flex items-center }
.ho-date-picker label { 
	text-transform: uppercase;
	font-size: 1.6rem;
	font-weight: 300;
	white-space: nowrap;
	margin-left: 4px;
	
	@apply text-gray-400
}

.ho-date-picker input.ho-date-input{
	width: 105px; font-size: 1.4rem;
	margin-left: 8px;
}

.ho-date-picker .ho-date-input { 
	border: none; 
	background: transparent;
	font-weight: 800;	
}

.ho-date-picker .ho-date-input input[type="text"] { 
	display: none;
	font-size: 1.4rem; 
}

.ho-filter .list-box { @apply leading-7 }

</style>