<template>
	<div class="ho-spinner-overlay">
		<div class="ho-spinner-wrapper">
			<div class="ho-spinner"><div></div><div></div><div></div><div></div></div>
      <p>Loading data ...</p>
		</div>
	</div>
</template>

<style lang="postcss" scoped>
.ho-spinner-overlay {
	display: table;
	z-index: 9998;
	left: 0; top: 0;
	position: fixed;
	width: 100%; height: 100%;
	-webkit-backdrop-filter: blur(3px);
	backdrop-filter: blur(3px);
	background: rgba(0,0,0,0.2);
}

.ho-spinner-wrapper {
	display: table-cell;
  vertical-align: middle;
	text-align: center;
  color: #6868ac;
}

.ho-spinner {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.ho-spinner div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #6868ac;
  border-radius: 50%;
  animation: ho-spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #6868ac transparent transparent transparent;
}
.ho-spinner div:nth-child(1) {
  animation-delay: -0.45s;
}
.ho-spinner div:nth-child(2) {
  animation-delay: -0.3s;
}
.ho-spinner div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes ho-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>