<template>
	<div class="list-box">
		<Listbox>
			<div class="relative flex items-center h-full">
				<ListboxLabel v-if="label && label.length > 0">{{label}}:</ListboxLabel>
				<ListboxButton class="flex items-center p-2 pl-3 text-left">
					<span class="block w-auto truncate">{{ selectionLabel }}</span>
					<span class="flex items-center pointer-events-none">
						<SelectorIcon class="w-5 h-5 text-gray-400" aria-hidden="true" />
					</span>
				</ListboxButton>

				<transition
					leave-active-class="transition duration-100 ease-in"
					leave-from-class="opacity-100"
					leave-to-class="opacity-0"
				>
					<ListboxOptions class="absolute w-full py-1 mt-1 overflow-auto bg-white shadow-lg max-h-60 ring-black ring-opacity-5 focus:outline-none">
						<ListboxOption
							v-slot="{ active, selected }"
							v-for="(selection) in selections"
							:key="selection.value"
							:value="selection.value"
							as="template"
							@click="onSelect(selection)"
						>
							<li :class="[ active ? 'text-amber-900 bg-amber-100' : 'text-gray-900', 'cursor-default select-none relative py-2 pl-10 pr-4' ]">
								<span :class="[selected ? 'font-medium' : 'font-normal','block truncate']">{{ selection.label }}</span>
								<span v-if="selected" class="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
									<CheckIcon class="w-5 h-5" aria-hidden="true" />
								</span>
							</li>
						</ListboxOption>
					</ListboxOptions>
				</transition>
			</div>
		</Listbox>
	</div>
</template>

<script setup lang="ts">
import { computed, ref, Ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { i18n } from '@/i18n';
import {
  Listbox,
  ListboxLabel,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
} from '@headlessui/vue';
import { CheckIcon, SelectorIcon } from '@heroicons/vue/solid';
import IListBoxSelectionsType from './interfaces/iListBoxSelectionsType';

const localization = useI18n();

const props = defineProps({
  className: {
    type: String,
    defualt: ""
  },
  label: {
    type: String,
    default: i18n.global.t('list-box.select')
  },
  selections: {
    type: Array as () => IListBoxSelectionsType[] | undefined
  },
  multiple: {
    type: Boolean,
    default: () => false
  }
}) 
const emit = defineEmits(['onListBoxSelect']);
const currentSelections: Ref<IListBoxSelectionsType[]> = ref([]);

const onSelect = (selection: IListBoxSelectionsType) => {
  if (props.multiple) {
    if (props.selections && props.selections.length > 0) {
      const foundCurrSelectionIndex = currentSelections.value.findIndex(s => s.value === selection.value);
      if (foundCurrSelectionIndex > -1) {
        currentSelections.value.splice(foundCurrSelectionIndex, 1);
      } else {
        currentSelections.value.push(selection)
      }
      
      emit('onListBoxSelect', selection);
    }
  } else {
    currentSelections.value = [selection];
    emit('onListBoxSelect', selection);
  }
}

const selectionLabel = computed(() => {
  if (currentSelections.value && currentSelections.value.length >= 1) {
    return currentSelections.value.length > 1 
      ? `${currentSelections.value.length} ${localization.t('list-box.filters')}` 
      : `${currentSelections.value[0].label}`
  }

  return localization.t('list-box.no-selection');
});
</script>
