<template>
	<th>
		<div class="ho-filter mt-2" v-if="filterActive && item && item.filterOptions">
			<list-box 
				class=""
				v-if="item.filterOptions.type === 'select'"
				:label="''"
				:selections="item.filterOptions?.options"
				@onListBoxSelect="handleListBoxSelect"
			/>

			<div class="ho-date-picker" v-if="item.filterOptions.type === 'dateRange'">
				<date-range @onChange="handleDateRangeChange" />
			</div>
		</div>
	</th>
</template>

<script setup lang="ts">
import { ref, Ref } from 'vue';
import ListBox from "../form/list-box.vue";
import IListBoxSelectionsType from "../form/interfaces/iListBoxSelectionsType";
import DateRange from "../form/date-range.vue";
import IDateRangeValues from "../form/interfaces/iDateRangeValues";
import IGridHeadCellConfig from './interfaces/iGridHeadCellConfig';

const props = defineProps({
	item: {
		type: Object as () => IGridHeadCellConfig,
	},
	filterActive: {
		type: Boolean,
		default: () => false
	}
});
const emit = defineEmits(['onFiltersChange']);
const filterSelection: Ref<IListBoxSelectionsType | null> = ref(null);
		
const handleListBoxSelect = (selection: IListBoxSelectionsType) => {
	filterSelection.value = selection;

	if (props.item?.filterOptions) {
		const selectedFilter = {
			field: props.item?.filterOptions.name,
			value: selection.value
		};

		emit('onFiltersChange', [selectedFilter]);
	}
}

const handleDateRangeChange = ({ startDate, endDate }: IDateRangeValues) => {
	emit('onFiltersChange', [
		{
			name: 'startDate',
			value: startDate
		},
		{
			name: 'endDate',
			value: endDate
		}
	]);
};

</script>

<style lang="postcss">
th .ho-filter .ho-date-picker { @apply flex items-center }
th .ho-filter .ho-date-picker span { @apply mx-2 }

th .ho-filter .ho-date-picker input.ho-date-input{
	width: 80px;
	@apply inline	h-10
}

th .ho-filter .list-box { @apply leading-7 }

</style>