<template>
	<div class="ho-modal-overlay ui-test-modal-dialog" :class="{'scrollable': scrollableModalOverlay}" id="ho-modal-dialog" @keyup.enter="handleKeyUp" @keyup.esc="handleKeyUp" tabindex="-1">
		<div class="ho-modal-wrapper" ref="wrapperDomRef">
			<div class="ho-modal-container ui-test-modal-container" :style="getBodyStyle()" ref="modalContainer">
				<div class="ho-modal-header ui-test-modal-header" v-if="headerText && headerText.length > 0">
					{{headerText}}
				</div>

				<div class="ho-modal-body" :class="{ 'hide-footer': hideFooter }">
					<slot name="body">
						<p class="ui-test-modal-body" v-if="body">{{body}}</p>
					</slot>
				</div>

				<div class="ho-modal-footer ui-test-modal-footer" v-if="!hideFooter">
					<slot name="footer">
						<button class="btn ui-test-ok-button" @click="handleOk" :disabled="disableOk" v-if="!hideFooter ? !hideOk : false">
							{{okButtonText}}
						</button>
						<button class="btn btn-outline ui-test-cancel-button" @click="handleCancel" :disabled="disableCancel" v-if="!hideFooter ? !hideCancel : false">
							{{cancelButtonText}}
						</button>
					</slot>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { onMounted, onBeforeUnmount, ref, Ref } from 'vue'
import { i18n } from '@/i18n';

const props = defineProps({
	headerText: String,
	body: {
		type: [String],
		default: () => undefined
	},
	hideFooter: {
		type: Boolean,
		default: false
	},
	hideCancel: {
		type: Boolean,
		default: true
	},
	hideOk: {
		type: Boolean,
		default: false
	},
	okButtonText: {
		type: String,
		default: i18n.global.t('common.ok')
	},
	cancelButtonText: {
		type: String,
		default: i18n.global.t('common.cancel')
	},
	disableOk: {
		type: Boolean,
		default: false
	},
	disableCancel: {
		type: Boolean,
		default: false
	},
	width: Number
});

const emit = defineEmits(['onOk', 'onCancel']);

const modalContainer: Ref<HTMLElement | null> = ref(null);
const wrapperDomRef:Ref<HTMLElement | null> = ref(null);
const scrollableModalOverlay = ref(true);

onMounted(() => {
	setSize();
	document.getElementById('ho-modal-dialog')?.focus();

	addModalBodySizeListener();
});

onBeforeUnmount(() => window.removeEventListener('resize', setSize));

const addModalBodySizeListener = () => {
	window.addEventListener('resize', setSize);

	const modalBody = wrapperDomRef.value?.getElementsByClassName('ho-modal-body')[0];
	
	if (window.ResizeObserver) {
		const resizeObserver = new ResizeObserver(() => setSize());
		modalBody && resizeObserver.observe(modalBody);
	}
};

const setSize = () => {
		const windowObj:Window = window;
		const windowHeight = windowObj.innerHeight;
		const wrapperHeight = wrapperDomRef.value?.clientHeight;

		const wrapperHeightLarger = wrapperHeight && wrapperHeight > windowHeight;

		if (scrollableModalOverlay.value === false && (wrapperHeightLarger)) {
			scrollableModalOverlay.value = true;
		} else if (scrollableModalOverlay.value === true && (!wrapperHeightLarger)) {
			scrollableModalOverlay.value = false;
		}
};

const getBodyStyle = () => {
	return { 
		maxWidth: `${props.width || 400}px`
	}
}

const handleOk = () => {
	emit('onOk');
}

const handleCancel = () => {
	emit('onCancel');
}

const handleKeyUp = (event : KeyboardEvent) =>{
	if (event.key == 'Enter' && !props.disableOk) {
		handleOk();
	} else if (event.key == 'Escape' && !props.disableCancel) {
		handleCancel();
	}
}
</script>

<style lang="postcss" scoped>
.ho-modal-overlay {
	display: table;
	z-index: 9998;
	left: 0; top: 0;
	position: absolute;
	width: 100%; height: 100%;
	-webkit-backdrop-filter: blur(3px);
	backdrop-filter: blur(3px);
	background: rgba(0,0,0,0.2);
}

.ho-modal-wrapper {
	display: table-cell;
	vertical-align: middle;
}

.ho-modal-container {
	margin: 0px auto;
	background-color: #fff;
	border-radius: 2px;
}

.ho-modal-header, 
.ho-modal-footer { padding: 16px 16px; }

.ho-modal-body { 
	padding: 0px 16px; 
}

.ho-modal-header {
	height: 50px;
	text-transform: uppercase;
	font-weight: 800;
	font-size: 1.6rem;
}

.ho-modal-footer {
	height: 80px;
	display: flex;
	justify-content: flex-end;
}

.ho-modal-overlay.scrollable {
	overflow: auto;
	display: block;
}

.ho-modal-overlay.scrollable .ho-modal-wrapper {
	display: block;
	vertical-align: middle;
	margin-top: 32px; margin-bottom: 32px;
}

</style>