<template>
	<ho-modal-dialog
		:headerText="localization.t('declarant-data-dialog.declarant-data-title')"
		:width="640"
		disableOk
		disableCancel
		hideFooter
	>
		<template v-slot:body>
			<declarant-data-form :declarantData="profileData" @onClose="handleDialogClose"/>
		</template>
	</ho-modal-dialog>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import HoModalDialog from "../modal/ho-modal-dialog.vue";
import DeclarantDataForm from "./declarantDataForm/index.vue";
import IProfile from "@/interfaces/users/iProfile";
import IDeclarantExtended from '@/interfaces/users/iDeclarantExtended';

const localization = useI18n();

defineProps({
	profileData: {
		type: Object as () => IProfile & IDeclarantExtended,
	}
});
const emit = defineEmits(['onUpdateDeclarantClose']);

const handleDialogClose = (data: IProfile & IDeclarantExtended) => {
	emit("onUpdateDeclarantClose", data);
};
</script>
