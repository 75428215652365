<template>
	<div class="ho-table-controls flex items-center px-3 @apply rounded-t ui-test-table-controls" :class="{ 'ho-grid-filters-active': filtersActive }">
		<div class="actions button-group flex items-center ml-4">
			<label :class="{ disabled: selectionLabelDisabled }" class="mr-2 ui-test-selection-label">{{selectionLabel}}</label>
			<slot name="topBarPlaceholder"></slot>
			<button v-if="selectedItemIds.length > 0" class="btn btn-bare btn-light ui-test-clear-button" @click="handleClearSelectionClick">{{localization.t('grid.clear-selection')}}</button>
		</div>
		<div class="data-options ml-auto">
			<div class="btn-group paging flex justify-end items-center ui-test-paging" v-if="!wantCustomPage">
				<button class="btn btn-xs btn-bare text-peri-400 ui-test-prev-button" @click="onPrevPage" :disabled="prevButtonDisabled"><ChevronLeftIcon class="w-8" /></button>
				<label class="label-pages ui-test-switch" :class="{ disabled: loading.grid || loading.export }" @click="onSwitch">{{listOptions.pageIndex + 1}} / {{totalPages}}</label>
				<button class="btn btn-bare text-peri-400 ui-test-next-button" @click="onNextPage" :disabled="nextButtonDisabled"><ChevronRightIcon class="w-8" /></button>
			</div>
			
			<div class="btn-group flex justify-end items-center input-container ui-test-custom-page" v-if="wantCustomPage">
				<reply-icon class="switch-paging w-6 mr-4 cursor-pointer text-peri-400 hover:text-peri-500 ui-test-switch" @click="onSwitch" />
				<label class="switch-label">Go to</label>
				<input type="number" @input="handleChangePageInput" @keyup.enter="onCustomPage" v-model="customPage" name="customPage" placeholder="1" min="1" :max="totalPages"/>
				<button class="btn btn-bare ml-1 text-peri-400 ui-test-go-to-button" @click="onCustomPage" :disabled="goToButtonDisabled" ><ChevronDoubleRightIcon class="w-8" /></button>
			</div>
		</div>
	</div>

	<table class="ho-grid ho-static-head ho-min-width @apply rounded-b ui-test-grid" :class="{ 'ho-grid-filters-active': filtersActive }">
		<grid-head 
			:loading="loading.grid"
			:columnsConfig="gridHeadConfig"
			:selectAllMode="isSelectAllMode"
			:sortOptions="listOptions.sort"
			@onFiltersActive="handleFiltersActive"
			@onFiltersChange="handleFiltersChange"
			@onSortingChange="handleSortingChange"
			@onSelectAllChange="handleSelectAllChange"
		/>
		<grid-body 
			v-if="loading.grid || visibleData.length > 0"
			:loading="loading.grid"
			:selectable="false"
			:selectedItemIds="selectedItemIds"
			:selectionExcludingItemIds="selectionExcludingItemIds"
			:selectAllMode="isSelectAllMode"
			:data="visibleData" 
			:columnsConfig="gridHeadConfig" 
			@onSelectionChange="handleSelectionChange"
		/>
		<tbody class="no-data ui-test-no-data" v-if="!loading.grid && (!visibleData || visibleData.length === 0)">
			<tr>
				<td :rowspan="gridHeadConfig.length">{{noDataMessage}}</td>
			</tr>
		</tbody>
	</table>
</template>

<script setup lang="ts">
import { computed, ref, toRef, watch, Ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { ChevronLeftIcon, ChevronRightIcon, ChevronDoubleRightIcon, ReplyIcon } from '@heroicons/vue/outline';
import gridHead from './grid-head.vue';
import gridBody from './grid-body.vue';
import IGridConfig from "./interfaces/iGridConfig";
import IGridHeadCellConfig from "./interfaces/iGridHeadCellConfig";
import IInvoice from '@/interfaces/invoices/iInvoice';
import IListOptions from '@/interfaces/invoices/iListOptions';
import IListFilter from '@/interfaces/invoices/iListFilter';
import IProfile from '@/interfaces/users/iProfile';

const localization = useI18n();

const emit = defineEmits(["onChangePage", "onResetSelection", "onChangeFilters", "onChangeSorting", "onChangeSelection", "onChangeSelectAllMode"]);
const props = defineProps({
	config: Object as () => IGridConfig,
	data: Array as () => IInvoice[],
	total: Number,
	listOptions: {
		type: Object as () => IListOptions,
		default: () => { return {} }
	},
	loading: {
		type: Object,
		default: () => {
			return {
				export: false,
				grid: false
			}
		}
	},
	hasDataErrors: {
		type: Boolean,
		default: false
	},
	profileData: Object as () => IProfile,
	isSelectAllMode: {
		type: Boolean,
		default: false
	},
	selections: {
		type: Object as () => { itemIds: string[], excludedItemIds: string[] },
		default: () => {
			return {
				itemIds: [],
				excludedItemIds: []
			}
		}
	},
	rowKeysConfig: {
		type: Array as () => {
			key: string,
			label?: string,
			class?: string,
			width?: number | string,
			minWidth?: number
		}[]
	}
});
		
const selectedItemIds = ref(props.selections?.itemIds);
const selectionExcludingItemIds = ref(props.selections?.excludedItemIds);

const data: Ref<IInvoice[] | undefined> = toRef(props, 'data');
const filtersActive = ref(false);
const firstLoadCompleted = ref(false);
const customPage = ref(props.listOptions.pageIndex + 1);
const wantCustomPage = ref(false);

const firstLoadWatch = watch(() => props.loading.grid, () => {
	if (props.loading.grid === true && firstLoadCompleted.value === false) {
		firstLoadCompleted.value = true;
		firstLoadWatch();
	}
});

watch(()=> props.listOptions.pageIndex, (current, prev)=>{
	if (current === 0 && current < prev) {
		customPage.value = 1;
	} else {
		customPage.value = current + 1;
	}
});

const selectionLabelDisabled = computed(() => {
	
	return props.loading.export
		|| props.loading.grid 
		|| props.total === 0
		|| props.hasDataErrors;
});

const totalPages = computed(() => {
	const totalPagesCount = Math.ceil((props.total || 0) / props.listOptions.pageSize);
	return totalPagesCount > 0 ? totalPagesCount : 1;
});

const gridHeadConfig = computed(() => {
	return (props.rowKeysConfig || [])
		.map((rowConfig) => {
			const headConfig: IGridHeadCellConfig  = {
				key: rowConfig.key,
				label: rowConfig.label || rowConfig.key
			}

			if (rowConfig.width) {
				headConfig.width = rowConfig.width;
			}

			if (rowConfig.minWidth) {
				headConfig.minWidth = rowConfig.minWidth;
			}

			if (rowConfig.class) {
				headConfig.class = rowConfig.class;
			}

			const filterOption = (props.config?.filterFields || []).find(f => f.name.toLowerCase() === rowConfig.key.toLowerCase());
			headConfig.filterOptions = filterOption;

			const sortField = (props.config?.sortFields || []).find(f => f.field.toLowerCase() === rowConfig.key.toLowerCase());
			headConfig.sortField = sortField;
			
			return headConfig;
		});

});

const visibleData = computed(() => {
	const allData = data.value;
	const visibleFields = (props.rowKeysConfig || []).map(rConfig => rConfig.key);
	const filteredData = (allData || []).map(d => {
		const field = {} as IInvoice;
		visibleFields.forEach(f => (field as {[key:string]: any})[f] = (d as {[key:string]: any})[f]);
		return field;
	});

	return filteredData;
});

const selectionLabel = computed(() => {
	let label = localization.t('grid.export');

	if (props.loading.grid) {
		return localization.t('grid.loading-data')
	}

	if (props.loading.export) {
		return localization.t('grid.exporting')
	}

	if (props.total === 0) {
		return firstLoadCompleted.value === true 
			? localization.t('grid.no-data')
			: label;
	}

	if (selectedItemIds.value?.length === 0) {
		label = `${label} ${localization.t('grid.all')} ${(props.total || 0)}`;
	} else {
		label = `${label} ${localization.t('grid.selected')} ${selectedItemIds.value?.length}`;
	}
	label = `${label} ${selectedItemIds.value?.length === 1 ? localization.t('grid.invoice'): localization.t('grid.invoices')}: `

	return label;
});

const noDataMessage = computed(() => {
	return firstLoadCompleted.value === true 
		? localization.t('grid.no-invoices')
		: localization.t('grid.use-filters')
});

const prevButtonDisabled = computed(() => {
	return props.loading.grid || props.loading.export || props.listOptions.pageIndex === 0
});

const nextButtonDisabled = computed(() => {
	const reachedEnd = props.listOptions.pageIndex >= Math.ceil((props.total || 0) / props.listOptions.pageSize) - 1;
	return props.loading.grid || props.loading.export || reachedEnd;
});

const goToButtonDisabled = computed(() => {
	return props.loading.grid || props.loading.export || !customPage.value
});

const resetSelections = () => {
	emit("onResetSelection");
}

const onNextPage = () => {
	const newPageValue = props.listOptions.pageIndex + 1;
	customPage.value = newPageValue + 1;
	onChangePage(newPageValue);
}

const onPrevPage = () => {
	const newPageValue = props.listOptions.pageIndex - 1;
	customPage.value = newPageValue + 1;
	onChangePage(newPageValue);
}

const onCustomPage = () => {
	if (customPage.value) {
		wantCustomPage.value = !wantCustomPage.value;
		onChangePage(customPage.value - 1);
	}
}

const onChangePage = (newPage: number) => {
	resetSelections();
	emit('onChangePage', newPage);
}

const onSwitch = () => {
	wantCustomPage.value = !wantCustomPage.value;
}

const addToSelections = (value: string, selectionsCollection: string[]) => {
	const hasItem = selectionsCollection.indexOf(value) > -1;

	if (!hasItem) {
		selectionsCollection.push(value);
	} else {
		selectionsCollection.splice(selectionsCollection.indexOf(value), 1);
	}
	emit("onChangeSelection", selectionsCollection);
}

const handleChangePageInput = () => {
	if (customPage.value && customPage.value > totalPages.value) {
		customPage.value = totalPages.value;
	} else if (customPage.value && customPage.value < 1 && (-customPage.value) >= 0) {
		customPage.value = 1;
	}
}

const handleSelectionChange = (newSelection: string) => {
	if (!props.isSelectAllMode) {
		addToSelections(newSelection, selectedItemIds.value);
	} else {
		addToSelections(newSelection, selectionExcludingItemIds.value);
	}
}

const handleClearSelectionClick = () => {
	resetSelections();
}

const handleFiltersActive = (isFiltersActive: boolean) => {
	filtersActive.value = isFiltersActive;
}

const handleFiltersChange = (filter: IListFilter[]) => {
	emit('onChangeSelectAllMode', false);
	resetSelections();
	emit('onChangeFilters', filter);
}

const handleSortingChange = (field: string, value: "asc" | "desc") => {
	emit('onChangeSelectAllMode', false);
	resetSelections();
	emit('onChangeSorting', field, value);
}

const handleSelectAllChange = (isSelectAllChecked: boolean) => {
	resetSelections();
	emit('onChangeSelectAllMode', isSelectAllChecked);
}

</script>

<style lang="postcss">

.ho-table-controls {
	height: 56px; 
	background: #fff;
}

.ho-table-controls label { text-transform: uppercase }
.ho-table-controls label.normal-case { text-transform: none }
.ho-table-controls label.disabled { @apply text-gray-400 }

.ho-grid { 
	height: calc(100% - 126px );
	background: rgba(255, 255, 255, 0.7)
}

.ho-grid thead { height: 42px; white-space: nowrap; }
.ho-grid tbody { height: calc(100% - 42px); }

.ho-grid tbody.no-data tr,
.ho-grid tbody.no-data tr:hover td { background: transparent; cursor: initial; }
.ho-grid tbody.no-data tr td { text-align: center; font-size: 1.6rem; color: #999; border: none; }

.ho-grid.ho-grid-filters-active thead { height: 97px; }
.ho-grid.ho-grid-filters-active tbody { height: calc(100% - 97px); }

.input-container > input{ 
	box-shadow: inset 0 0 4px 0 rgb(0 0 0 / 0.2); 
	width: 60px;
	@apply text-xl ml-4 border-gray-300
}

.ho-table-controls .data-options .paging label.label-pages { cursor: pointer; @apply border-b border-peri-500 text-xl text-peri-500 }
.ho-table-controls .data-options .paging label.label-pages.disabled { cursor: not-allowed; @apply border-none text-gray-200 }

.ho-table-controls .data-options .paging .switch-paging { display: none }
.ho-table-controls .data-options .paging:hover .switch-paging { display: inline-block }

@media (max-height: 767px) {
	.ho-table-controls { height: 48px; }
	.ho-grid { height: calc(100% - 120px ); }
	.ho-grid thead { height: 42px; white-space: nowrap; }
	.ho-grid tbody { height: calc(100% - 42px); }
}
</style>