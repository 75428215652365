import { Composer } from 'vue-i18n';
import { i18n } from '@/i18n';
import IConnection from "@/interfaces/users/iConnection";
import IProfile from "@/interfaces/users/iProfile";
import IUser from "@/interfaces/users/iUser";
import { get, put } from "./apiService";
import { syncSession } from "./authService";

const localization: Composer = i18n.global;

const BASE_URL = 'auth/myprofile';

export const getMyUser = async (): Promise<IUser> => {
	const result = await get<IUser>(BASE_URL);

	if (result.status !== 200) {
		const errorMessage = localization.t("errors.cannot-connect-server", { status: result.status, statusText: result.statusText});
		throw new Error(errorMessage);
	}

	if (!result.data) {
		const errorMessage = localization.t("errors.cannot-get-profile-data");
		throw new Error(errorMessage);
	}

	//use mock connections
	// result.data.connections = mockConnections;

	return result.data;
}

export const updateMyProfile = async (newProfileData: IProfile): Promise<void> => {
	const body = newProfileData;

	await put<any>(BASE_URL, body);
}

export const switchConnection = async (connectionDb: string): Promise<void> => {
	await syncSession(connectionDb);
}

export const editUserConnections = async (connections: IConnection[]): Promise<void> => {
	const path = `${BASE_URL}/connections`;

	const body = connections;

	await put<any>(path, body);
}

export const getActiveConnection = (userConnections: IConnection[]): IConnection | undefined => {
	if (userConnections.length === 0) {
		return undefined;
	}

	const activeConnection = (userConnections).find(c => c.active);

	return activeConnection;
}