import { Composer } from 'vue-i18n';
import { i18n } from '@/i18n';

const localization: Composer = i18n.global;

type ValidationValueType = string | number | Date;

export type ValidatorType = 'len' | 'notEmpty' | 'pattern' | 'unique';

export interface IValidator {
	type: ValidatorType,
	options?: any,
	message?: string
}

interface ILengthValidator {
	min?: number,
	max?: number
}

interface IStringValidator {
	pattern: RegExp
}

interface IUniquenessValidator {
	collection: string[],
	caseSensitive?: boolean
}

export const validate = (value: ValidationValueType, validators: IValidator[] = []): string[] => {
	const errors: string[] = [];
	validators.forEach(v => {
		let isValid = true;

		switch (v.type) {
			case 'len':
				if (v.options as ILengthValidator) {
					v.options.min = v.options.min ? Math.abs(v.options.min) : v.options.min;
					v.options.max = v.options.max ? Math.abs(v.options.max) : v.options.max;

					isValid = len(value?.toString(), v.options.min, v.options.max);
					if (!isValid) {
						const defaultMessage = v.options.max
						? localization.t('common.validators.length-between', { min: v.options.min || 0, max: v.options.max })
						: localization.t('common.validators.length-min', { min: v.options.min || 0 });
						
						errors.push(v.message || defaultMessage);
					}
				}
				break;
			case 'notEmpty':
				isValid = notEmpty(value);
				if (!isValid) {
					errors.push(v.message || localization.t('common.validators.not-empty'));
				}
				break;
			case 'pattern':
				isValid = matchPattern(value.toString(), (v.options as IStringValidator).pattern);
				if(!isValid){
					errors.push(v.message || localization.t('common.validators.valid-format'))
				}
				break;
			case 'unique':
				const options = v.options as IUniquenessValidator;
				isValid = isUnique(value.toString(), options.collection, options.caseSensitive);
				if(!isValid){
					errors.push(v.message || localization.t('common.validators.uniqueness'))
				}
				break;
			default:
				break;
		}
	});

	return errors;
}

const notEmpty = (value: ValidationValueType) => {
	return value !== undefined 
		&& value !== null
		&& value.toString().trim().length > 0;
}

const len = (value: string, min = 0, max?: number) => {
	if (max !== undefined) {
		return (value || "").trim().length >= min && (value || "").trim().length <= max;
	} else {
		return (value || "").trim().length >= min;
	}
}

const matchPattern = (value: string, pattern: RegExp) => {
	return pattern.test(value);
}

const isUnique = (value: string, collection: string[], caseSensitive = false) => {
	const delegate = caseSensitive 
		? (item: string) => item.toLowerCase() === value.toLowerCase()
		: (item: string) => item === value

	const foundItems = collection.filter(delegate);
	return foundItems.length === 0;
}