export class ApiError extends Error {
    readonly statusCode: number | undefined;
    readonly innerMessage: string | undefined;

    constructor(
        message: string, 
        innerMessage?: string,
        statusCode?: number,
    ) {
        super(message);
        this.innerMessage = innerMessage
        this.statusCode = statusCode;
    }
}


// Status codes from BE
// 100: "unexpected system error"
// 102: "invalid username/password"
// 103: "password mismatch"
// 110: "odoo connection error (general)"
// 111: "no configured odoo connections for user"
// 112: "invalid odoo connection configuration"
// 120: "invalid user-provided argument (general)"
// 121: "invalid date-range argument"
// 122: "invalid company argument"
// 123: "invalid ledger argument"
// 124: "invalid file-type argument"
// 125: "invalid UCN argument"
// 126: "invalid submitter argument"
// 130: "unauthorized operation attempted"
// 140: "invalid query results (general)"
// 141: "invalid query results - resource not found"
// 142: "invalid query results - returned 0 results, while expecting some"
// 150: "invalid odoo-related request (general)"
// 160: "incorrect document structure (for odoo invoices export)"
