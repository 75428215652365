import { Composer } from 'vue-i18n';
import { i18n } from '@/i18n';
import { ApiError } from '@/types/errorTypes';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import mockResponses from './mocks/mockApiEndpoints';

const IS_MOCK_ACTIVE = process.env.VUE_APP_MOCK_API;
console.log('mapi: ',IS_MOCK_ACTIVE)

console.log("env: " + process.env.NODE_ENV);
console.log("api " + process.env.VUE_APP_API_URL);
console.log("mock api is " + IS_MOCK_ACTIVE !== undefined ? "active" : "off");

const apiProps = {
	baseURL: process.env.VUE_APP_API_URL || `http://localhost:3001/`,
	headers: { 'Accept': 'application/json' }
};
const api = axios.create(apiProps);

let odooSessionId: number | null;
let accessToken: string | null;

const localization: Composer = i18n.global;

export const get = <T>(path: string, query?: string, options?: AxiosRequestConfig): Promise<any | AxiosResponse<T>> => {
	const url = createUrl(path, query);
	if (odooSessionId) {
		addSessionIdHeader();
	}
	if (accessToken) {
		addAuthenticationHeader();
	}
	return IS_MOCK_ACTIVE 
		? getMockedAxiosResponse(path)
		: api.get<T>(url, options)
				.catch((error: any) => throwErrorMessage(error));
}

export const post = <T>(path: string, body: unknown, query?: string | null, options?: AxiosRequestConfig): Promise<AxiosResponse<T>> => {
	const url = createUrl(path, query);

	if (odooSessionId) {
		addSessionIdHeader();
	}
	if (accessToken) {
		addAuthenticationHeader();
	}

	return IS_MOCK_ACTIVE 
		? getMockedAxiosResponse(path)
		: api.post<T>(url, body, options)
				.catch((error: any) => throwErrorMessage(error));
}

export const put = <T>(path: string, body: unknown, query?: string | null, options?: AxiosRequestConfig): Promise<AxiosResponse<T>> => {
	const url = createUrl(path, query);

	if (odooSessionId) {
		addSessionIdHeader();
	}
	if (accessToken) {
		addAuthenticationHeader();
	}

	return IS_MOCK_ACTIVE 
		? getMockedAxiosResponse(path)
		: api.put<T>(url, body, options)
				.catch((error: any) => throwErrorMessage(error));
}

export const setAuthenticationToken = (token: string | null): void => {
	accessToken = token;
}
const addAuthenticationHeader = (): void => {
	api.defaults.headers['Authorization'] = `Bearer ${accessToken}`;
}

export const setSessionId = (sessionId: number | null): void => {
	odooSessionId = sessionId;
}

const addSessionIdHeader = (): void => {
	api.defaults.headers['Odoo-Session-Id'] = odooSessionId;
}

const createUrl = (path: string, query?: string | null): string => {
	let url = path;
	if (query) {
		if(query[0] === '&'){
			query = query.slice(1);
		}
		url = `${url}?${query}`;
	}

	return url;
}

const handleUnAuthorizedError = (error: any) => {
	let message = localization.t('errors.invalid-authentication');
	let innerMessage = undefined;

	if (error.response.data && error.response.data.message && error.response.data.code) {
		console.error(error.response.data.message);
		innerMessage = error.response.data.message;
		message = localization.t(`errors.codes.${error.response.data.code}`);
		if (error.response.data.message.includes('session')) {
			localStorage.removeItem('odooSessionId');
		} else {
			localStorage.clear();
		}
	} else {
		console.error(error.response);
		innerMessage = error.response.message;
		localStorage.clear();
	}
	throw new ApiError(message, innerMessage, error.response.status);
}

const throwErrorMessage = (error: any) => {
	if (error.response) {
		if (error.response.status === 401){
			handleUnAuthorizedError(error);
		}

		if (error.response.data.code) {	
			console.error(error.response.data.message);
			throw new ApiError(localization.t(`errors.codes.${error.response.data.code}`), error.response.data.message, error.response.status);
		} else if (error.response.data instanceof Blob) {
			return error.response.data.text()
				.then((response: any) => {
					const jsonResponse = JSON.parse(response);
					console.error(jsonResponse.message);
					throw new ApiError(localization.t(`errors.codes.${jsonResponse.code}`), jsonResponse.message, error.response.status);
				})
				.catch((error: ApiError) => {
					throw new ApiError(error.message, error.innerMessage, error.statusCode);
				})
		} else {
			console.error(error.response);
			throw new ApiError(localization.t('errors.something-wrong'), error.response.message, error.response.status);
		}
	} else {
		console.error(error);
		throw new ApiError(localization.t('errors.something-wrong'), error.message);
	}
}

export const getMockedAxiosResponse = (path: string, status = 200, statusText = "Ok", headers = {}): Promise<AxiosResponse> => {
	console.info(`Mock response path=${path}`);

	return new Promise((res) => {
		setTimeout(() => res({
			statusText,
			headers,
			config: {},
			status, 
			data: mockResponses[path]
		}), 500);
	});
}
