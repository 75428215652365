<template>
	<div>
		<button :disabled="exportButtonsDisabled" class="btn btn-xs ui-test-export-button" :class="{'btn-loading': loading}" @click="handleExportClick('CSV')">{{localization.t('export-actions.csv')}}</button>
		<button :disabled="exportButtonsDisabled" v-if="!loading" class="btn btn-xs ui-test-export-button" @click="handleExportClick('TXT')">{{localization.t('export-actions.txt')}}</button>
		<button :disabled="exportButtonsDisabled || exportViesButtonDisabled" v-if="!loading" class="btn btn-xs ui-test-export-vies-button" @click="handleExportViesClick()">{{localization.t('export-actions.vies')}}</button>
	</div>

	<label v-if="hasDataErrors" class="text-lg ml-2 p-3 text-white bg-red-500 ui-test-disabled-buttons-label">{{localization.t('export-actions.cannot-export')}}</label>

</template>
<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import IExportType from '@/interfaces/invoices/iExportType';

const localization = useI18n();

defineProps({
	loading: {
		type: Boolean,
		default: false
	},
	hasDataErrors: {
		type: Boolean,
		default: false
	},
	exportButtonsDisabled: {
		type: Boolean,
		default: false
	},
	exportViesButtonDisabled: {
		type: Boolean,
		default: false
	}
});
const emit = defineEmits(['onExportClick', 'onExportViesClick']);
const handleExportClick = (fileType: 'CSV' | 'TXT') => {
	const exportType: IExportType = {
		fileType,
		includeVies: false
	}
	emit('onExportClick', exportType);
}
const handleExportViesClick = () => {
	emit('onExportViesClick');
}
</script>
