<template>
	<ho-form class="form-gray mt-4" v-model="formData"
		@onOk="handleChangeSave" @onCancel="handleChangeCancel"
		:okButtonText="loading ? localization.t('user-data-dialog.changing') : localization.t('user-data-dialog.change')"
		:cancelButtonText="localization.t('common.cancel')" :disableOk="!isValid || loading"
	>
		<template v-slot:body>
			<div class="form-row">
				<div class="form-cell flex-none">
					<ho-text :label="localization.t('user-data-dialog.old-password')" v-model="formData.oldPassword"
						:isMasked="true" :validation="validators.oldPassword"
						modelKey="oldPassword"
						@onValidStateChange="handleValidationStateChange" />
				</div>
				<br />
				<div class="form-cell flex-none">
					<ho-text :label="localization.t('user-data-dialog.new-password')" v-model="formData.newPassword"
						:isMasked="true" :validation="validators.newPassword"
						modelKey="newPassword"
						@onValidStateChange="handleValidationStateChange" />
				</div>
				<br />
				<div class="form-cell flex-none">
					<ho-text :label="localization.t('user-data-dialog.confirm')" v-model="formData.confirmPassword"
						:isMasked="true" :validation="validators.confirmPassword"
						modelKey="confirmPassword"
						@onValidStateChange="handleValidationStateChange" />
					<p v-if="passwordsNotMatchMessage" class="text-red-500">{{ passwordsNotMatchMessage }}</p>
				</div>
			</div>
		</template>
	</ho-form>
</template>

<script setup lang="ts">
import { computed, ref, watch, Ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { getValidators } from './validations';
import HoForm from "../../form/ho-form.vue";
import HoText from "../../form/ho-text.vue";

const localization = useI18n();

type ValidationField = 'oldPassword' | 'newPassword' | 'confirmPassword';

const validators = getValidators(localization.t);

defineProps({
	loading: Boolean
});
const emit = defineEmits(['onChangePasswordClose']);

const formData = ref({
    oldPassword: '',
    newPassword: '',
    confirmPassword: ''
});

const validState = ref({
	oldPassword: false,
	newPassword: false,
	confirmPassword: false
});
const passwordsNotMatchMessage: Ref<string | null> = ref(null);

const isValid = computed(() => {
	return validState.value.oldPassword
		&& validState.value.newPassword
		&& validState.value.confirmPassword
		&& formData.value.newPassword === formData.value.confirmPassword
});

watch(() => [formData.value.confirmPassword, formData.value.newPassword], () => {
	if (formData.value.confirmPassword.localeCompare(formData.value.newPassword) && formData.value.confirmPassword !== '') {
		passwordsNotMatchMessage.value = localization.t('user-data-dialog.passwords-mismatch')
	} else {
		passwordsNotMatchMessage.value = null;
	}
})

const handleChangeCancel = () => {
	emit('onChangePasswordClose');
};

const handleChangeSave = () => {
	emit('onChangePasswordClose', formData.value.oldPassword, formData.value.newPassword);
};

const handleValidationStateChange = (fieldKey: ValidationField, value: boolean) => {
	validState.value[fieldKey] = value;
};
</script>
