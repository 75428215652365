<template>
	<th>
		<span class="label">
			{{item?.label}}

			<button 
				v-if="item?.sortField" 
				class="ho-icon-btn sort-btn ui-test-sort-button" 
				:class="{ sorted: sortActive, asc: sortActive ==='asc', active: item.sortField.field === sortOptions?.field }"
				@click="onSortClick"
				:disabled="loading"
			>
				<SelectorIcon class="ho-btn-state-normal text-gray-400" />
				<SelectorIcon class="ho-btn-state-hover" />
				<ChevronUpIcon class="ho-btn-state-up" :class="{ active: sortActive ==='asc'}" />
				<ChevronDownIcon class="ho-btn-state-down" :class="{ active: sortActive ==='desc'}" />
			</button>
		</span>
	</th>
</template>

<script setup lang="ts">
import { ref, Ref } from 'vue';
import { SelectorIcon, ChevronUpIcon, ChevronDownIcon } from '@heroicons/vue/outline';
import IGridHeadCellConfig from "./interfaces/iGridHeadCellConfig";
import ISortOptions from '../../interfaces/invoices/iSortOptions';

const props = defineProps({
	item: {
		type: Object as () => IGridHeadCellConfig,
	},
	sortOptions: {
		type: Object as () => ISortOptions
	},
	loading: Boolean
});
const emit = defineEmits([ 'onSortingChange']);
const sortActive: Ref<'asc' | 'desc' | undefined> = ref(props.item?.sortField?.selectedSort);

const onSortClick = () => {
	const newSortValue: 'asc' | 'desc' = sortActive.value === 'asc' ? 'desc' : 'asc';

	sortActive.value = newSortValue;

	emit('onSortingChange', props.item?.sortField?.field, newSortValue);
}
</script>