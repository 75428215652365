import { Composer } from 'vue-i18n';
import { i18n } from '@/i18n';
import { formatToApi, getEndDateFromPeriod, getStartDateFromPeriod } from "@/utils/dateTime";
import { get, post } from "./apiService";
import IInvoice from '@/interfaces/invoices/iInvoice';
import IListFilter from "@/interfaces/invoices/iListFilter";
import ISortOptions from "@/interfaces/invoices/iSortOptions";
import IListOptions from "@/interfaces/invoices/iListOptions";
import { IInvoiceResponsePayload, IInvoicesResponsePayload } from "@/interfaces/invoices/iInvoicesResponsePayload";
import IConvertDeclarationResult from "@/interfaces/home/iConvertDeclarationResult";
import IDeclarationData from "@/interfaces/home/iDeclarationData";
import IProfile from "@/interfaces/users/iProfile";
import IDeclarantExtended from "@/interfaces/users/iDeclarantExtended";
import IExportType from "@/interfaces/invoices/iExportType";

const localization: Composer = i18n.global;

export enum InvoiceLedger {
	SALES = 'sales',
	PURCHASES = 'purchases'
}

export enum ApiFilterFields {
	LEDGER = 'ledger',
	DATE_FROM = 'datefrom',
	DATE_UNTIL = 'dateuntil',
	COMPANY_ID = 'companyid',
	PAGE_INDEX = 'pageindex',
	PAGE_SIZE = 'pagesize',
	INCLUDE_CANCELLED = 'includecancelled',
	WITH_VIES = 'withvies'
}

export const getPaged = async (options?: IListOptions): Promise<{
	data: IInvoice[],
	total: number
}> => {
	const urlPath = 'odoo/invoices';
	const urlQueryParams = buildGetListUrlQueryParams(options);

	const response = await get<IInvoicesResponsePayload>(urlPath, urlQueryParams);

	const invoices: IInvoice[] = (response.data.invoices || []).map((item: IInvoiceResponsePayload) => {
		const {
			customer,
			date,
			number,
			taxBase,
			status,
			customerVat,
			customerCountry,
			nraDocType,
			tax,
			invoiceDate
		} = item;

		const mappedItem: IInvoice = {
			customer,
			date: new Date(date),
			number,
			taxBase,
			status,
			customerVat,
			customerCountry,
			nraDocType,
			tax,
			invoiceDate: new Date(invoiceDate)
		}

		return mappedItem;
	});

	return {
		data: invoices,
		total: response.data.totalCount
	};
}

export const exportData = async (
	ids: string[],
	selectAllMode: boolean,
	exportType: IExportType,
	listOptions?: IListOptions,
	declarantData? : IProfile & IDeclarantExtended
) : Promise<void> => {
	const urlPath = 'odoo/exportfull';

	const body = {
		companyId: listOptions?.filters?.find(f=>f.field === 'company')?.value,
		ledger: listOptions?.filters?.find(f=>f.field === 'ledger')?.value,
		fileType: exportType.fileType.toLowerCase()
	}

	const startDate = listOptions?.filters?.find(f=>f.field === 'startDate')?.value as Date;
	const endDate = listOptions?.filters?.find(f=>f.field === 'endDate')?.value as Date;
	
	if (startDate && endDate) {
		(body as any).dateFrom = formatToApi(startDate);
		(body as any).dateUntil = formatToApi(endDate);
	} else {
		throw new Error(localization.t("errors.export-missing-data"));
	}

	if (declarantData) {
		(body as any).submittedBy = declarantData;
	}

	const queryParams = [];
	const includeCancelledFilter = listOptions?.filters?.find(f=>f.field === 'includeCancelled');
	if (includeCancelledFilter) {
		queryParams.push(includeCancelledFilter);
	}
	const withViesOption = {
		field: 'withVies',
		value: exportType.includeVies
	};
	queryParams.push(withViesOption);
	
	const urlQueryParams = urlAddFilters('', queryParams);

	const result = await post<Blob>(urlPath, body, urlQueryParams, { responseType: 'blob' });

	if (result.headers['content-disposition'] && result.headers['content-disposition'].length > 0) {
		const fileInformation = result.headers['content-disposition'].split('; ');
		if (fileInformation.length >= 2) {
			const fileInformationName = fileInformation[1].split('=')[1];
			const fileName = fileInformationName && fileInformationName.length > 0 ? fileInformationName : 'export';

			downloadFile(result.data, fileName);
		} else {
			console.error(`Response headers content-disposition malformed: ${result.headers['content-disposition']}`);
			throw new Error(localization.t("errors.export-download-failed"));
		}
	} else {
		console.error(`Response headers content-disposition malformed: ${result.headers['content-disposition']}`);
		throw new Error(localization.t("errors.export-download-failed"));
	}
}

export const importData = async (formDataBody: FormData, formData: IDeclarationData) : Promise<IConvertDeclarationResult> => {
	const urlPath = 'odoo/declaration/convert';
	const { fullName, unifiedCivilNumber, month, year, companyId } = formData;

	if (month && companyId && year) {
		const startDate = formatToApi(getStartDateFromPeriod(month, year));
		const endDate = formatToApi(getEndDateFromPeriod(month, year));
		formDataBody.append('companyId', companyId.toString());
		formDataBody.append('dateFrom', startDate);
		formDataBody.append('dateUntil', endDate);
	} else {
		throw new Error(localization.t("errors.import-missing-data"));
	}

	formDataBody.append('ucn', unifiedCivilNumber);
	formDataBody.append('fullName', fullName);
	const result = await post(urlPath, formDataBody, null, {
		headers: {
			'Content-Type': 'multipart/form-data'
		},
		responseType: 'blob'
	});

	const blob = new Blob([result.data as BlobPart]);

	if (result.headers['content-disposition'] && result.headers['content-disposition'].length > 0) {
		const fileInformation = result.headers['content-disposition'].split(';');
		if (fileInformation.length >= 2) {
			const fileInformationName = fileInformation[1].trim();
			const fileName = fileInformationName.replace('filename=', '');
			const downloadBlobUrl = window.URL.createObjectURL(blob);

			return {
				downloadUrl: downloadBlobUrl,
				fileName
			};
		} else {
			console.error(`Response headers content-disposition malformed: ${result.headers['content-disposition']}`);
			throw new Error(localization.t("errors.import-convert-failed"));
		}
	} else {
		console.error(`Response headers content-disposition malformed: ${result.headers['content-disposition']}`);
		throw new Error(localization.t("errors.import-convert-failed"));
	}
}

const urlAddFilters = (url: string, filters: IListFilter[]): string => {
	filters.forEach((f) => {
		let urlValue = f.value;
		let urlField = f.field;

		if (typeof f.value == 'boolean') {
			if (f.field === 'includeCancelled') {
				urlField = ApiFilterFields.INCLUDE_CANCELLED;
			} else if (f.field === 'withVies') {
				urlField = ApiFilterFields.WITH_VIES;
			}
		} else {
			if (f.field === 'startDate') {
				urlValue = formatToApi(new Date(f.value));
				urlField = ApiFilterFields.DATE_FROM;
			} else if (f.field === 'endDate') {
				urlValue = formatToApi(new Date(f.value));
				urlField = ApiFilterFields.DATE_UNTIL;
			} else if (f.field === 'company') {
				urlField = ApiFilterFields.COMPANY_ID;
			}
		}

		url = `${url}&${urlField}=${urlValue}`
	});

	return url;
}

const urlAddSorting = (url: string, sort: ISortOptions) => {
	url = `${url}&sort=${sort.field}&direction=${sort.direction}`;
	return url;
}

const buildGetListUrlQueryParams = (options?: IListOptions) => {
	let pSize = 10;
	let pIndex = 0;
	if (options) {
		const { pageIndex, pageSize } = options;
		pIndex = pageIndex;
		pSize = pageSize;
	}

	let urlQueryParams = `${ApiFilterFields.PAGE_INDEX}=${pIndex}&${ApiFilterFields.PAGE_SIZE}=${pSize}`;

	if (options?.filters && options?.filters.length > 0) {
		const parsedFilters = options.filters.filter(f => f.value !== null || f.value !== undefined);
		urlQueryParams = urlAddFilters(urlQueryParams, parsedFilters);
	}

	if (options?.sort && options.sort.field.length > 0) {
		urlQueryParams = urlAddSorting(urlQueryParams, options.sort);
	}

	return urlQueryParams;
}

const downloadFile = (fileData: BlobPart, fileName: string) => {
	const url = window.URL.createObjectURL(new Blob([fileData]));
	const link = document.createElement('a');
	link.href = url;
	link.setAttribute('download', fileName);
	document.body.appendChild(link);
	link.click();
}