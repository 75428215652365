<template>
	<label>{{label}}</label>
	<input 
		class="bg-white"
		:autocomplete="disableAutocomplete ? 'new-password' : 'on'"
		:aria-autocomplete="disableAutocomplete ? 'none' : 'both'"
		:type="isMasked ? 'password' : 'text'"
		v-model="value"
		:disabled="disableInput"
	/>
	<p v-if="errorMessage" class="text-red-500">{{errorMessage}}</p>
</template>

<script setup lang="ts">
import { IValidator, validate } from '@/utils/validator';
import { computed, ref, watch, WritableComputedRef, Ref } from 'vue'

const props = defineProps({
	label: String,
	modelValue: [String, Number],
	modelKey: String,
	errors: String,
	placeholder: String,
	validation: {
		type: Array as () => IValidator[]
	},
	disableInput: {
		type: Boolean,
		default: false
	},
	resetValidation: {
		type: Boolean,
		default: false
	},
	isMasked: {
		type: Boolean,
		default: false
	},
	disableAutocomplete: {
		type: Boolean,
		default: false
	}
});
const emit = defineEmits(['update:modelValue', 'onValidStateChange', 'onResetValidation']);

const errorMessage: Ref<string | null> = ref(null);

watch(()=> props.resetValidation, (current)=>{
	if(current === true){
		errorMessage.value = null;
		emit('onResetValidation');
	}
});

const value: WritableComputedRef<string | number> = computed({
	get: () => {
		return props.modelValue || "";
	},
	set: (value) => {
		const errors = validate(value, props.validation as IValidator[]);
		const identifier = props.modelKey ? props.modelKey : props.label;

		if (errors && errors.length > 0) {
			errorMessage.value = errors.join(", ");
			emit('onValidStateChange', identifier, false);
		} else {
			errorMessage.value = null;
			emit('onValidStateChange', identifier, true);
		}
		emit('update:modelValue', value);
	}
});
</script>
