import { format, getDaysInMonth } from "date-fns";

const uiFormat = 'dd.MM.yyyy';
const apiFormat = 'yyyy-MM-dd';

export const formatToUi = (dateTime: Date): string => {
	return format(dateTime, uiFormat);
}

export const formatToApi = (dateTime: Date): string => {
	return format(dateTime, apiFormat);
}

export const getStartDateFromPeriod = (month: number, year: number): Date => {
	const startDate = new Date(year, month - 1, 1);
	return startDate;
}
export const getEndDateFromPeriod = (month: number, year: number): Date => {
	const dateToDay = getDaysInMonth(new Date(year, month - 1));
	const endDate = new Date(year, month - 1, dateToDay);
	return endDate;
}