<template>
	<label>{{label}}</label>
	
	<select v-model="value">
		<option v-for="(option, index) in selectOptionsList" :key="index" :value="option.value">{{ option.label }}</option>
	</select>
	
	<p v-if="errorMessage" class="text-red-500">{{errorMessage}}</p>
</template>

<script setup lang="ts">
import { IValidator, validate } from '@/utils/validator';
import { computed, Ref, ref, WritableComputedRef } from 'vue';

// TODO: importing type props interface to use in defineProps should work with vue@3.3.4
// but it seems still not working. Test around
interface IHoSelectProps {
	label?: string,
	placeholder?: string,
	modelValue?: string | number,
	modelKey: string
	validation?: IValidator[],
	selectOptionsList: {
		label: string,
		value: string,
		diabled?: boolean
	}[]
}

const props = defineProps<IHoSelectProps>();

const emit = defineEmits(['update:modelValue', 'onValidStateChange', 'onResetValidation']);

const errorMessage: Ref<string | null> = ref(null);

const value: WritableComputedRef<string | number> = computed({
	get: () => {
		return props.modelValue || "";
	},
	set: (value) => {
		const errors = validate(value, props.validation as IValidator[]);
		const identifier = props.modelKey ? props.modelKey : props.label;

		if (errors && errors.length > 0) {
			errorMessage.value = errors.join(", ");
			emit('onValidStateChange', identifier, false);
		} else {
			errorMessage.value = null;
			emit('onValidStateChange', identifier, true);
		}
		emit('update:modelValue', value);
	}
});
</script>

