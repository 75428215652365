<template>
	<ho-form v-model="currentConnection" class="form-gray mt-4"
		@onOk="handleModalOk"
		@onCancel="handleModalCancel"
		:okButtonText="loading ? (editMode ? localization.t('common.saving') : localization.t('common.adding')) : (editMode ? localization.t('common.save') : localization.t('common.add'))"
		:cancelButtonText="localization.t('common.cancel')"
		:disableOk="disableOkButton"
	>
		<template v-slot:body>
			<div class="form-row">
				<h3 class="connection-label">
					{{ editMode ? `${connection.db}` : localization.t('edit-connection-form.add-new-connection') }}
					<span :class="connection.active ? 'active' : ''" v-if="editMode">
						{{ connection.active ? ` (${localization.t('edit-connection-form.active')})` : ` (${localization.t('edit-connection-form.not-active')})` }}
					</span>
				</h3>
				<div>
					<div class="form-cell flex-1">
						<ho-text modelKey="url"
							:label="localization.t('edit-connection-form.labels.url')"	
							v-model="currentConnection.url"
							:validation="getValidators().url"
							:resetValidation="resetValidation"
							@onResetValidation="handleResetValidation"
							@onValidStateChange="handleValidationStateChange"
						/>
					</div>
					<br />

					<div class="flex">
						<div class="form-cell flex-1">
							<ho-text modelKey="alias"
								:label="localization.t('edit-connection-form.labels.alias')"
								v-model="currentConnection.alias"
								:validation="getValidators().alias"
								:resetValidation="resetValidation"
								@onValidStateChange="handleValidationStateChange"
								@onResetValidation="handleResetValidation"
							/>
						</div>
						<div class="form-cell flex-1">
							<ho-text modelKey="db"
								:label="localization.t('edit-connection-form.labels.database')"
								v-model="currentConnection.db"
								:validation="getValidators().db"
								:resetValidation="resetValidation"
								@onValidStateChange="handleValidationStateChange"
								@onResetValidation="handleResetValidation"
							/>
						</div>
						<div class="form-cell flex-1">
							<ho-text modelKey="version"
								:label="localization.t('edit-connection-form.labels.version')"
								v-model="currentConnection.version"
								:validation="getValidators().version"
								:resetValidation="resetValidation"
								@onValidStateChange="handleValidationStateChange"
								@onResetValidation="handleResetValidation"
							/>
						</div>
					</div>

					<br />

					<div>
						<div class="form-cell flex-none">
							<ho-text modelKey="username"
								:label="localization.t('common.user.username')"
								v-model="currentConnection.username"
								:validation="getValidators().username"
								:resetValidation="resetValidation"
								@onValidStateChange="handleValidationStateChange"
								@onResetValidation="handleResetValidation"
							/>
						</div>
					</div>

					<br />

					<div class="flex" v-if="!hidePassword">
						<div class="form-cell flex-1">
							<ho-text modelKey="password"
								:label="localization.t('edit-connection-form.labels.password')"
								v-model="currentConnection.password"
								:isMasked="isPasswordMasked"
								:validation="getValidators().password"
								:resetValidation="resetValidation"
								@onValidStateChange="handleValidationStateChange"
								@onResetValidation="handleResetValidation"
							/>
						</div>
						<span class="ml-2 mb-5 text-center self-end" @click="togglePasswordMask">
							<eye-icon class="w-7 h-7" v-if="isPasswordMasked" />
							<eye-off-icon class="w-7 h-7" v-if="!isPasswordMasked" />
						</span>
					</div>
				</div>
			</div>
		</template>
	</ho-form>
</template>
<script setup lang="ts">
import { computed, onBeforeMount, ref, toRaw, watch, Ref } from 'vue'
import { useI18n } from 'vue-i18n';
import HoForm from "../form/ho-form.vue";
import HoText from "../form/ho-text.vue";
import IConnection from '@/interfaces/users/iConnection';
import { EyeIcon, EyeOffIcon } from '@heroicons/vue/solid';
import { IValidator } from '@/utils/validator';

export type ValidationField = 'db' | 'url' | 'username' | 'password' | 'version' | 'alias';
const localization = useI18n();

const uniquenessValidatorCollections = {
	db: [],
	url: [],
	username: [],
	password: [],
	version: [],
	alias: []
} as { [key: string]: any[] }

const getValidators = () => {
	const validators: {
		[key:string]: any[]
	} = {
		db: [
			{
				type: 'notEmpty',
				message: localization.t('edit-connection-form.validators.database-required')
			}, {
				type: 'unique',
				options: {
					collection: uniquenessValidatorCollections.db,
					caseSensitive: true
				},
				message: localization.t('edit-connection-form.validators.database-uniqueness')
			}
		],
		url: [
			{
				type: 'notEmpty',
				message: localization.t('edit-connection-form.validators.url-required')
			},
			{
				type: 'pattern',
				options: {
					pattern: /^(http[s]?:\/\/)?(www\.)?.+\.[a-zA-Z0-9]+$/,
				},
				message: localization.t('edit-connection-form.validators.url-format')
			}
		],
		username: [{
			type: 'notEmpty',
			message: localization.t('edit-connection-form.validators.username-required')
		}],
		password: [{
			type: 'notEmpty',
			message: localization.t('edit-connection-form.validators.password-required')
		}],
		version: [
			{
				type: 'notEmpty',
				message: localization.t('edit-connection-form.validators.version-required')
			},
			{
				type: 'pattern',
				options: {
					pattern: /^\d+$/,
				},
				message: localization.t('edit-connection-form.validators.version-digits')
			}
		],
		active:[],
		alias:[{
			type: 'unique',
			options: {
				collection: uniquenessValidatorCollections.alias,
				caseSensitive: true
			},
			message: localization.t('edit-connection-form.validators.alias-uniqueness')
		}]
	}

	return validators;
}

const props = defineProps({
	connection: {
		type: Object as () => IConnection,
		default: () => ({ active: false } as IConnection)
	},
	loading: Boolean,
	editMode: {
		type: Boolean,
		default: false
	},
	resetValidation: {
		type: Boolean,
		default: false
	},
	allConnections: {
		type: Array as () => IConnection[],
		default: () => []
	},
	hidePassword: {
		type: Boolean,
		default: false
	}
});
const emit = defineEmits(['onEditConnectionClose', 'onCancel', 'onResetValidation']);
const isPasswordMasked = ref(true);
const currentConnection: Ref<IConnection> = ref({ ...toRaw(props.connection) });
const resetValidation = ref(props.resetValidation);

const validState: Ref<{ [key: string]: boolean }> = ref({
	url: currentConnection.value.url ? true : false,
	db: currentConnection.value.db ? true : false,
	version: currentConnection.value.version ? true : false,
	username: currentConnection.value.username ? true : false,
	password: currentConnection.value.password ? true : false,
	alias: true
});

const disableOkButton = computed(() => {
	return props.loading
		|| !validState.value.url
		|| !validState.value.db
		|| !validState.value.version
		|| !validState.value.username
		|| !validState.value.password
		|| !validState.value.alias
});

watch(() => props.resetValidation, () => {
	handleResetValidation();
	resetValidation.value = props.resetValidation;
});

watch(() => props.connection, () => {
	currentConnection.value = { ...props.connection };
	validState.value = {
		url: currentConnection.value.url ? true : false,
		db: currentConnection.value.db ? true : false,
		version: currentConnection.value.version ? true : false,
		username: currentConnection.value.username ? true : false,
		password: currentConnection.value.password ? true : false,
		alias: true
	};
	addCollectionToUniquenessValidators()
});

const addCollectionToUniquenessValidators = () => {
	(Object.keys(uniquenessValidatorCollections) as ValidationField[]).forEach((field: ValidationField) => {
		const currentValidator = getValidators()[field].find(v => v.type === 'unique') as IValidator;
		if (currentValidator) {
			const collection = [...props.allConnections]
			.map(conn => conn[field])
			.filter(key => key !== currentConnection.value[field] && key !== null);
			
			uniquenessValidatorCollections[field] = collection
		}
	});
}

onBeforeMount(() => addCollectionToUniquenessValidators());

const togglePasswordMask = () => {
	isPasswordMasked.value = !isPasswordMasked.value
}

const handleResetValidation = () => {
	validState.value = {
		url: currentConnection.value.url ? true : false,
		db: currentConnection.value.db ? true : false,
		version: currentConnection.value.version ? true : false,
		username: currentConnection.value.username ? true : false,
		password: currentConnection.value.password ? true : false,
		alias: true
	};
	emit('onResetValidation')
}
const handleValidationStateChange = (key: ValidationField, value: boolean) => {
	validState.value[key] = value;
};
const handleModalOk = () => {
	currentConnection.value.version = Number(currentConnection.value.version);

	emit('onEditConnectionClose', currentConnection.value);
}
const handleModalCancel = () => {
	emit('onEditConnectionClose');
};
</script>

<style lang="postcss" scoped>
.connection-label .active {
	color: rgb(80, 216, 2);
}
</style>