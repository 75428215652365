import IInvoice from "@/interfaces/invoices/iInvoice";
import { IInvoicesResponsePayload } from "@/interfaces/invoices/iInvoicesResponsePayload";

const invoices: IInvoice[] = [];
for (let i = 0; i < 25; i++) {
	const invoice = {
		nraDocType: "Test",
		number: `0000034062${i}`,
		invoiceDate: new Date("2021-10-15"),
		date: new Date("2021-10-15"),
		customerVat : "BG115855159",
		customer: "Гиргинови ЕООД",
		customerCountry: "Bulgaria",
		taxBase: 399.36,
		tax: 399.36,
		status: "posted",
	} as IInvoice;

	if (i === 2) {
		invoice.customerVat = "";
	}
	invoices.push(invoice)
}
const dataMock: IInvoicesResponsePayload = {
	invoices,
	totalCount:225
}

export const invoicesMockResponse = dataMock;