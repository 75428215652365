<template>
	<ho-modal-dialog
		:headerText="connectionsDialogLabel"
		:okButtonText="loading.connect ? localization.t('common.connecting') : localization.t('common.connect')"
		:cancelButtonText="localization.t('common.close')"
		:disableOk="disableConnectButton"
		:disableCancel="showEditForm || hideCloseButton"
		:hideOk="showEditForm"
		:hideCancel="showEditForm || hideCloseButton"
		:hideFooter="showEditForm"
		:width="640"
		:allowKeyPress="true"
		@onOk="handleModalOk"
		@onCancel="handleModalCancel" 
	>
		<template v-slot:body>
			<div class="form-row transparent" v-if="showSelectConnection">
				<p>{{localization.t('ho-modal-connect.connect-message')}}</p>

				<div class="flex items-end">
					<div class="autocomplete-holder">
						<label>{{localization.t('ho-modal-connect.my-connections')}}</label>
						<Autocomplete
							:placeholder="localization.t('connections-dialog.select-connection')"
							:options="options"
							:defaultValue="currentConnection?.db || ''"
							:canClear="false"
							@onChange="handleConnectionSelect"
						/>
					</div>

					<button class="btn h-14 flex mb-4" @click="handleEditModeClick" :disabled="disableEditButton">
						<span class="w-8 h-8 text-center"><pencil-alt-icon class="w-7 h-7" /></span>
						{{localization.t('connections-dialog.edit')}}
					</button>
					<button class="btn h-14 flex mb-4" @click="handleAddNewButtonClick">
						<span class="w-8 h-8 text-center"><plus-icon class="w-7 h-7" /></span>
						{{localization.t('connections-dialog.new')}}
					</button>
				</div>
			</div>
			
			<edit-connection-form 
				v-if="showEditForm"
				:loading="loading.connection"
				:connection="currentConnection || newConnectionTemplate"
				:editMode="editMode"
				:resetValidation="resetValidation"
				:allConnections="connections"
				@onEditConnectionClose="handleEditConnectionClose"
				@onResetValidation="handleResetValidation"
			/>
			
		</template>
	</ho-modal-dialog>
</template>

<script setup lang="ts">
import { computed, ref, Ref } from 'vue';
import { useI18n } from 'vue-i18n';
import HoModalDialog from "../modal/ho-modal-dialog.vue";
import IConnection from '@/interfaces/users/iConnection';
import Autocomplete from '../form/autocomplete.vue';
import { PlusIcon, PencilAltIcon } from '@heroicons/vue/outline';
import EditConnectionForm from '@/components/app/edit-connection-form.vue';

const localization = useI18n();

const newConnectionTemplate: IConnection = {
	db: '',
	active: false,
	password: '',
	url: '',
	username: '',
	version: 14,
	alias: ''
};

const props = defineProps({
	connections: {
		type: Array as () => IConnection[],
		default: () => []
	},
	selectedConnection: {
		type: Object as () => IConnection | null,
		default: () => null
	},
	loading: {
		type: Object,
		default: () => {
			return {
				connect: false,
				connection: false,
				user: false
			}
		}
	},
	hideCloseButton: Boolean
});
const emit = defineEmits(['onConnectionsDialogClose', 'onEditConnectionsClose']);
const resetValidation = ref(false);
const editMode = ref(false);
const showEditForm = ref(false);

const activeConnectionIndex = props.connections.findIndex(c => c.active);
const indexOfCurrentConnection: Ref<number | null> = ref(activeConnectionIndex);

const connectionsDialogLabel = computed(() => {
	let label = localization.t('connections-dialog.my-connections');
	if (props.loading.connect) {
		label = localization.t('common.connecting');
	} else if (props.loading.user || props.loading.connection) {
		label = localization.t('connections-dialog.loading');
	} else if (showEditForm.value) {
		if (editMode.value) {
			label = localization.t('connections-dialog.edit');
		} else {
			label = localization.t('connections-dialog.create');
		}
		label = `${label} ${localization.t('connections-dialog.connection')}`;
	}
	return label;
})

const options = computed(() => {
	if (props.connections) {
		return props.connections.map((conn: IConnection) => ({label: conn.alias? conn.alias : conn.db, value: conn.db}))
	} else {
		return [];
	}
});

const currentConnection = computed(() => {
	if (indexOfCurrentConnection.value !== null) {
		return props.connections[indexOfCurrentConnection.value];
	}

	return null;
});

const disableConnectButton = computed(() => {
	return props.loading.connection
		|| props.loading.connect
		|| props.loading.user
		|| showEditForm.value
		|| (currentConnection.value?.db ? currentConnection.value?.db.trim().length === 0 : true);
});
const disableEditButton = computed(() => {
	return props.loading.connection
		|| props.loading.connect
		|| props.loading.user
		|| (showEditForm.value && editMode.value)
		|| (currentConnection.value?.db ? currentConnection.value?.db.trim().length === 0 : true);
});

const showSelectConnection = computed(() => {
	let show = !showEditForm.value;
	return show;
});

const handleModalCancel = () => {
	emit('onConnectionsDialogClose');
};
const handleModalOk = () => {
	emit('onConnectionsDialogClose', currentConnection.value, true);
};

const handleEditConnectionClose = (connection?: IConnection) => {
	if (!connection) {
		emit('onEditConnectionsClose');
		showEditForm.value = false;

		return;
	}

	if (editMode.value === true) {
		editConnection(connection);
	} else {
		addConnection(connection);
	}
}

const addConnection = (connection: IConnection) => {
	const connections = [...props.connections];

	connections.push(connection);
	
	indexOfCurrentConnection.value = connections.length - 1;

	emit('onEditConnectionsClose', connections, false, () => showEditForm.value = false);
}

const editConnection = (connection: IConnection) => {	
	const connections = [...props.connections];

	if (indexOfCurrentConnection.value === null) {
		return;
	}

	connections.splice(indexOfCurrentConnection.value, 1, connection);
	emit('onEditConnectionsClose', connections, false, () => showEditForm.value = false);
}

const handleConnectionSelect = (selectedConnectionDb: string) => {
	editMode.value = showEditForm.value ? true : editMode.value;
	resetValidation.value = showEditForm.value ? true : false;

	const selectedConnection = {...props.connections?.find(conn => 
		conn.db === selectedConnectionDb
	)} as IConnection;

	if (selectedConnection) {
		indexOfCurrentConnection.value = props.connections?.findIndex(c => c.db === selectedConnection.db);
	} else {
		indexOfCurrentConnection.value = null;
	}
}

const handleAddNewButtonClick = () => {
	indexOfCurrentConnection.value = null;
	editMode.value = false;
	resetValidation.value = showEditForm.value ? true : false;
	showEditForm.value = true;
}

const handleEditModeClick = () => {
	editMode.value = true;
	resetValidation.value = showEditForm.value ? true : false;
	showEditForm.value = true;
}

const handleResetValidation = () => {
	resetValidation.value = false;
}
</script>