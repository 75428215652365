export const getCellWidthStyle = (width?: number|string, minWidth?: number) : string => {
	let cssClass = "";

	if (width) {
		let widthType = "";

		if (!isNaN(Number(width))) {
			widthType = `px`;
		}

		cssClass = width ? `width: ${width}${widthType};` : `width: auto;`;
		
		if (minWidth) {
			cssClass = `${cssClass} min-width: ${minWidth}px`;
		}

		return cssClass;
	}

	return `width: auto;`;
};