<template>
	<ho-modal-dialog
		:headerText="localization.t('export-vies-declaration.export-vies')"
		:width="640"
		disableOk
		hideFooter
	>
		<template v-slot:body>
			<div class="export-form">
				<p>{{localization.t('export-vies-declaration.export-after-fill')}}</p>
				<declarant-data-form
					vies
					:declarantData="profileData"
					:okButtonText="localization.t('export-vies-declaration.export')"
					:formLabel="localization.t('export-vies-declaration.exported-by')"
					@onClose="handleDialogClose"
				/>
			</div>
		</template>
	</ho-modal-dialog>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import HoModalDialog from "../modal/ho-modal-dialog.vue";
import DeclarantDataForm from "../profile/declarantDataForm/index.vue";
import IProfile from "@/interfaces/users/iProfile";
import IDeclarantExtended from '@/interfaces/users/iDeclarantExtended';

const localization = useI18n();

defineProps({
	profileData: Object as () => IProfile & IDeclarantExtended
});
const emit = defineEmits(["onExportModalClose"]);

const handleDialogClose = (data: IProfile & IDeclarantExtended) => {
	emit("onExportModalClose", data);
};
</script>