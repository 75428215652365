<template>
    <ho-modal-dialog :headerText="userDataDialogLabel" :hideCancel="false" :hideFooter="openChangePasswordDialog"
        :disableOk="openChangePasswordDialog" :width="480"
        :okButtonText="localization.t('user-data-dialog.change-password')"
        :cancelButtonText="localization.t('common.close')" @onOk="() => openChangePasswordDialog = true"
        @onCancel="handleModalCancel">
        <template v-slot:body>
            <div class="dialog-info-container" v-if="!openChangePasswordDialog">
                {{localization.t('common.user.username')}}: <b>{{ userData?.userName }}</b>
                <br/>
                {{localization.t('common.user.email')}}: <b>{{ userData?.email }}</b>
            </div>
            <change-password v-if="openChangePasswordDialog" :loading="loading"
                @onChangePasswordClose="handlePasswordDialogClose" />
        </template>
    </ho-modal-dialog>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import HoModalDialog from "../modal/ho-modal-dialog.vue";
import ChangePassword from "./changePasswordForm/index.vue";
import IUser from "@/interfaces/users/iUser";

const localization = useI18n();

defineProps({
    userData: {
        type: Object as () => IUser,
    },
    loading: Boolean
});

const emit = defineEmits(['onChangePasswordClose']);

const openChangePasswordDialog = ref(false);

const userDataDialogLabel = computed(() => {
    let label = localization.t('user-data-dialog.my-profile-title');
    if (openChangePasswordDialog.value) {
        label = localization.t('user-data-dialog.change-password');
    }

    return label;
})

const handlePasswordDialogClose = (oldPassword: string, newPassword: string) => {
    emit('onChangePasswordClose', oldPassword, newPassword);
    openChangePasswordDialog.value = false;
};

const handleModalCancel = () => {
    emit('onChangePasswordClose');
};

</script>
