<template>
	<ul>
		<li @click="handleSelect(locale)" v-for="(locale, i) in locales" :key="`locale-${i}`">
			<span class="w-8 h-8 text-center"><language-flags class="h-6 w-6 rounded-full" :selectedLanguage="locale" /></span>
			<label>{{ localization.t(`languages.${locale}FullName`) }}</label>
		</li>
	</ul>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import LanguageFlags from '@/components/svg/language-flags.vue';

const localization = useI18n();
const locales = localization.availableLocales;

const handleSelect = (locale: string) => {
	localization.locale.value = locale;
	localStorage.setItem('lang', locale);
}
</script>