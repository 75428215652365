export const calculateFormFullName = (firstName?: string, middleName?: string, lastName?: string): string => {
    let fullName = firstName ? firstName : '';

	if (middleName && middleName.length > 0) {
		fullName= fullName.length > 0 ? `${fullName} ${middleName}` : middleName;
	}

	if (lastName && lastName.length > 0) {
		fullName= fullName.length > 0 ? `${fullName} ${lastName}` : lastName;
	}

    return fullName;
}