<template>
	<tbody :class="{notSelectable: !selectable, loading: loading, 'ho-grid-tbody': !loading}" class="ui-test-grid-table-body">
		<grid-row v-for="(item, index) in !loading ? data : [1,2,3]" :key="`data-${index}`"
			:data="item"
			:loading="loading"
			:columnsConfig="columnsConfig"
			:filterableHeader="filterableHeader"
			:selectable="selectable"
			:checkedRows="checkedRows"
			@onCheckboxChange="handleCheckboxChange"
		/>
	</tbody>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import IInvoice from '@/interfaces/invoices/iInvoice';
import gridRow from './grid-row.vue';
import IGridHeadCellConfig from './interfaces/iGridHeadCellConfig';

const props = defineProps({
	data: Array as () => IInvoice[],
	columnsConfig: {
		type: Array as () => IGridHeadCellConfig[]
	},
	selectedItemIds: {
		type: Array,
		default: () => []
	},
	selectionExcludingItemIds: {
		type: Array,
		default: () => []
	},
	selectAllMode: Boolean,
	filterableHeader: {
		type: Boolean,
		default: () => false
	},
	loading: {
		type: Boolean,
		default: () => false
	},
	selectable: {
		type: Boolean,
		default: () => false
	}
});
const emit = defineEmits(['onSelectionChange']);
const checkedRows = ref(props.selectedItemIds);

watch(() => props.selectedItemIds.length, () => {
	if (props.selectable) {
		checkedRows.value = [...props.selectedItemIds];
	}
}, {
	immediate: true
});

watch(() => props.selectAllMode, (current, prev) => {
	if (props.selectable) {
		if (prev === false && current === true) {
			checkedRows.value = [...(props.data || []).map(d => d.number)];
		} else {
			checkedRows.value = [];
		}
	}
}, {
	immediate: true
});

const handleCheckboxChange = (id: string) => {
	if (props.selectable) {
		emit('onSelectionChange', id);
	}
}
</script>

<style lang="postcss" scoped>
	@keyframes fadeIn { 
		from { opacity: 0.3; } 
	}
	tbody { overflow-y: scroll;	}

	tbody.loading tr, 
	tbody.loading tr:hover td	{ background-color: transparent; cursor: initial; }

	tbody.notSelectable tr,
	tbody.notSelectable tr:hover td { cursor: initial; }

	.error-cell {
		background-color: #FFB0B0;
	}
	tr.error-row{
		background-color: #FFDEDE;
	}
</style>