import { invoicesMockResponse } from './invoicesResponseMock';
import IUser from '../../interfaces/users/iUser';

const mockLoginResponse = {
	userName: 'mock_user',
	accessToken: 'mock_token'
};

const mockConnectResponse = {
	odoo_user: 'mock_user',
	session_id: 1
};

const mockCompanies = [
	{ id: 1, name: 'Foo' }, { id: 2, name: 'baz long company name' }
];

const exportMockResponse = {};
const declarationConvertMockResponse = {};

const mockProfileResponse: IUser = {
	id: '1',
	userName: 'mockUser',
	email: 'mock@email.com',
	role: 'user',
	profileData: {
		address: 'Address 1',
		city: 'Sofia',
		declarantType: '',
		firstName: 'Mock',
		lastName: 'User',
		postalCode: '1111',
		ucn: '8937892374',
		middleName: ''
	},
	connections: [
		{
			alias: 'Odoo Connection One',
			active: true,
			db: 'odoo_db_1',
			password: 'pass',
			url: 'http://conn.odoo',
			username: 'conn1',
			version: 2
		}
	]
}

const mockEndpointsDictionary: { [key: string]: any } = {
	'auth/login': mockLoginResponse,
	'auth/myprofile': mockProfileResponse,
	'odoo/connect': mockConnectResponse,
	'odoo/companies': mockCompanies,
	'odoo/invoices': invoicesMockResponse,
	'odoo/exportfull': exportMockResponse,
	'odoo/declaration/convert': declarationConvertMockResponse
};

export default mockEndpointsDictionary;