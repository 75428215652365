<template>
	<label :for="`ho-toggle-${name}`" class="flex items-center cursor-pointer relative ho-toggle-component">
		<input type="checkbox" class="sr-only ui-test-toggle-input" 
			:id="`ho-toggle-${name}`" 
			:checked="isChecked"
			@change="onCheckboxChange()">
		<div class="toggle-bg bg-peri-200 rounded-full"></div>
		<span class="ml-2 text-gray-900 text-2xl uppercase ui-test-toggle-label">{{ label }}</span>
	</label>
</template>
<script setup lang="ts">
import { ref } from 'vue'
const props = defineProps({
	label: String,
	name: String,
	checked: Boolean
});
const emit = defineEmits(['onChange']);

const isChecked = ref(props.checked);
const onCheckboxChange = () => {
	isChecked.value = !isChecked.value;
	emit("onChange", isChecked.value);
};
</script>

<style lang="postcss" scoped>
	.toggle-bg { width: 3.6rem; height: 2.0rem }

	.toggle-bg:after {
		content: '';
		height: 1.8rem;
		width: 1.8rem;
		top: 0.1rem;
		left: 0.1rem;
		@apply absolute bg-white border border-gray-300 rounded-full transition shadow-sm;
	}

	input:checked + .toggle-bg:after {
		transform: translateX(1.6rem);
		@apply border-white;
	}

	input:checked + .toggle-bg {
		@apply bg-peri-500 border-peri-400;
	}
</style>