import { createI18n } from 'vue-i18n';

// On change of language, switch the /locales/_language_.json file
function loadLocaleMessages() {
    const locales = require.context(
        "@/locales",
        true,
        /[A-Za-z0-9-_,\s]+\.json$/i
    );
    const messages: any = {};
    locales.keys().forEach((key) => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i);
        if (matched && matched.length > 1) {
            const locale = matched[1];
            messages[locale] = locales(key);
        }
    });
    return messages;
}
export const i18n = createI18n({
    legacy: false,
    globalInjection: true,
    fullInstall: true,
    locale: localStorage.getItem("lang") || "bg",
    fallbackLocale: "en",
    messages: loadLocaleMessages()
});